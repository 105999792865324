<template>
 
 <div id="back">
  <a class="backbutton" href="/taskmanager">{{symbol}}任务面板</a>  
</div>
    <div class="version-container">
    
      <h2 class="version-title">CHANGELOG</h2>
      <div class="current-version">
        <p><strong>当前版本:</strong> {{ currentVersion.version }}</p>
        <p><strong>发布日期:</strong> {{ currentVersion.releaseDate }}</p>
        <h3>新功能和改进:</h3>
        <ul class="feature-list">
          <li v-for="(feature, index) in currentVersion.features" :key="index">
            {{ feature }}
          </li>
        </ul>
      </div>
  
      <div class="history-toggle" @click="toggleHistory">
        <p><span v-if="showHistory">⬆ 隐藏历史版本</span><span v-else>⬇ 显示历史版本</span></p>
      </div>
  
      <transition name="fade">
        <div v-if="showHistory" class="version-history">
          <h3>历史版本</h3>
          <div v-for="(version, index) in versionHistory" :key="index" class="version-item">
            <p><strong>版本:</strong> {{ version.version }}</p>
            <p><strong>发布日期:</strong> {{ version.releaseDate }}</p>
            <h4>新功能和改进:</h4>
            <ul class="feature-list">
              <li v-for="(feature, idx) in version.features" :key="idx">
                {{ feature }}
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentVersion: {
          version: 'v2.0.3',
          releaseDate: '2024-09-14',
          features: [
            '任务编辑状态视觉效果区分',
            '设置区域UI视觉效果提升',
            'vip功能:困难任务提醒工作',
            '任务编辑状态UI视觉提升'
          ],
        },
        versionHistory: [
        {
          version: 'v2.0.2',
          releaseDate: '2024-09-09',
          features: [
            '修复了图标相关的一些已知问题',
            '通知类UI视觉提升'
           
          ],
        },
        {
          version: 'v2.0.0',
          releaseDate: '2024-09-03',
          features: [
            '上线了changelog发布历史',
           
          ],
        },
        {
          version: 'v1.9.9',
          releaseDate: '2024-09-03',
          features: [
            '修复在目标树中添加任务不显示的bug',
            '改进页面加载速度',
            '修复了一些小错误',
          ],
        },
  {
    version: 'v1.9.8',
    releaseDate: '2024-08-15',
    features: [
      '支持右侧任务在目标树中移动到新的project(项目)',
      '更新用户指南',
    ],
  },
  {
    version: 'v1.9.7',
    releaseDate: '2024-07-15',
    features: [
      'vip用户选择更多的评价日期选项',
      '优化数据同步性能，减少页面加载时间',
      '修复了一些小的UI问题',
    ],
  },
  {
    version: 'v1.9.6',
    releaseDate: '2024-06-15',
    features: [
      '增加任务标签功能，支持为任务添加多种分类标签',
    ],
  },
  {
    version: 'v1.9.5',
    releaseDate: '2024-05-15',
    features: [
      '实现自动任务分配，根据团队成员的工作负载自动分配任务',
      '改进任务通知系统，增加提醒选项',
      '优化移动端的用户界面',
    ],
  },
  {
    version: 'v1.9.4',
    releaseDate: '2024-04-15',
    features: [
      '新增文件共享功能，支持在任务中上传和共享文件',
      '引入日历视图以更直观地查看任务截止日期',
      '修复导出报告时的格式问题',
    ],
  },
  {
    version: 'v1.9.3',
    releaseDate: '2024-03-15',
    features: [
      '增加用户自定义字段功能，允许用户自定义任务字段',
      '改进任务搜索功能，增加按标签和优先级搜索',
      '修复了一些小的安全漏洞',
    ],
  },
  {
    version: 'v1.9.2',
    releaseDate: '2024-02-15',
    features: [
      '支持任务的批量编辑，简化任务管理流程',
      '改进用户权限管理，增加细粒度的权限设置',
      '修复任务评论中URL链接无法点击的问题',
    ],
  },
  {
    version: 'v1.9.1',
    releaseDate: '2024-01-15',
    features: [
      '引入时间跟踪功能，支持记录任务所用时间',
      '优化任务详情页面布局，提高信息可读性',
      '修复了一些导致浏览器崩溃的Bug',
    ],
  },
  {
    version: 'v1.9.0',
    releaseDate: '2023-12-15',
    features: [
      '新增任务模板功能，简化常规任务的创建过程',
      '修复了在某些浏览器中页面加载缓慢的问题',
    ],
  },
  {
    version: 'v1.8.0',
    releaseDate: '2023-11-15',
    features: [
      '引入团队协作功能，支持任务的多成员协作',
      '改进任务评论功能，支持回复和引用评论',
      '修复任务截止日期在日历中显示错误的问题',
    ],
  },
  {
    version: 'v1.7.0',
    releaseDate: '2023-10-15',
    features: [
      '增加任务优先级设置功能，支持设置任务的重要程度',
      '优化任务的提醒通知机制，支持邮件和短信提醒',
      '修复移动设备上的一些UI显示问题',
    ],
  },
  {
    version: 'v1.6.0',
    releaseDate: '2023-09-15',
    features: [
      '新增子任务功能，支持任务的分解和管理',
      '增加用户活动日志，记录用户的操作历史',
      '修复了一些用户反馈的Bug',
    ],
  },
  {
    version: 'v1.5.0',
    releaseDate: '2023-08-15',
    features: [
      '引入任务看板视图，支持拖拽任务进行进度管理',
      '增加项目成员的权限管理功能',
      '修复了一些性能问题，提高了系统响应速度',
    ],
  },
  {
    version: 'v1.4.0',
    releaseDate: '2023-07-15',
    features: [
      '修复任务详情页面的样式问题',
    ],
  },
  {
    version: 'v1.3.0',
    releaseDate: '2023-06-15',
    features: [
     
      '改进用户界面，提升整体用户体验',
      '修复了一些浏览器兼容性问题',
    ],
  },
  {
    version: 'v1.2.2',
    releaseDate: '2023-05-15',
    features: [
      '优化用户界面',
      '增加新用户指南',
    ],
  },
  {
    version: 'v1.2.1',
    releaseDate: '2023-04-30',
    features: [
      '修复登录问题',
      '改进安全性能',
    ],
  },
  {
    version: 'v1.2.0',
    releaseDate: '2023-03-20',
    features: [
      '目标管理上线',
      '改进一些安全性问题',
    ],
  },
],
        showHistory: true,
      };
    },
    methods: {
      toggleHistory() {
        this.showHistory = !this.showHistory;
      },
      returnToTaskPanel() {
        this.$router.push('/taskmanager');
      }
    }
  };
  </script>
  
  <style scoped lang="scss">

  
#back{
  /* position: absolute;; */
  right: 0%;
  width: 200px;
  margin-left: auto;


  /* display:block; */

  
.backbutton{
  height: 45px;
    line-height: 45px;
    width: 150px;
    display: inline-block;
    vertical-align: top;
    margin: 0 15px;
    border: 1px solid #181828;
  
    color: #181828;
    text-decoration: none;
    background: snow;
    font-family: "handwriting1";
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: .1rem;
  
    border-radius:5px;
    overflow: hidden;
    text-align: center;

   
}

}

  .version-container {
  padding: 30px;
  background-color: #f8f9fa;
  border: none;
  border-radius: 12px;
  max-width: 60vw;
  margin: 30px auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.version-title {
  margin-bottom: 20px;
  color: #3a4f66;
  font-size: 2em;
  text-align: center;
  font-weight: 300;
}

.current-version {
  background-color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 25px;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
}

.feature-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 15px;
}

.feature-list li {
  padding-left: 20px;
  position: relative;
  margin-bottom: 8px;
}

.feature-list li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #6c757d;
}

.history-toggle {
  cursor: pointer;
  color: #5a6268;
  text-align: center;
  margin-top: 25px;
  transition: color 0.3s ease;
  font-weight: 500;
}

.history-toggle:hover {
  color: #3a4f66;
}

.version-history {
  margin-top: 25px;
  border-top: 1px solid #e9ecef;
  padding-top: 25px;
}

.version-item {
  margin-bottom: 25px;
  padding: 20px;
  background-color: #ffffff;
  border: none;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.03);
}

.version-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
}

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
  }
  </style>
  